import { useId, useState } from 'react';

import { Slider } from '@/components/slider';
import { useCRM } from '@/features/crm/hooks/use-crm.hook';
import { Button, Modal, type ModalProps } from '@/ui';

interface Props {
  onClose: ModalProps['onClose'];
}

const MONITOR_RANGES = ['1k', '5k', '15k', '25k+'];

export const CustomQuoteModal = ({ onClose }: Props) => {
  const inputId = useId();
  const crm = useCRM();
  const [selectedRange, setSelectedRange] = useState(1);
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    crm.sendEvent({
      name: 'event.request_quote',
      payload: { email, monitors: MONITOR_RANGES[selectedRange - 1] },
    });

    window.open(
      'https://calendly.com/marek-itrinity/uptimerobot-sales-partnership-consultation',
      '_blank',
    );
    onClose(false);
  };

  return (
    <Modal
      onClose={onClose}
      containerClassName="p-0 bg-transparent max-w-[718px]"
    >
      <div className="flex flex-col overflow-hidden rounded-md text-sm md:flex-row">
        <div
          style={{
            background:
              'linear-gradient(180deg, rgba(59,214,113,0.08) 0%, rgba(59,214,113,0.3) 100%)',
            backgroundColor: '#131a26',
          }}
          className="flex items-center justify-center py-5 md:w-[305px]"
        >
          <img
            src="/assets/images/enterprise-subscription.svg"
            alt="Enterprise subscription"
            className="w-[220px]"
            loading="lazy"
          />
        </div>

        <div className="flex flex-1 flex-col gap-4 bg-[#1C2434] p-7 text-left">
          <div>
            <h2 className="mb-2 text-2xl font-bold text-gray-50">
              How many monitors do you need?
            </h2>
            <p className="text-sm text-gray-400">
              UptimeRobot offers custom monitoring solutions tailored for
              agencies, service providers, software companies, and enterprises.
            </p>
          </div>

          <hr className="mb-3 border-gray-700" />

          <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <div>
              <label className="mb-1 block font-bold text-gray-50">
                Number of monitors
              </label>
              <p className="mb-3 text-sm text-gray-400">
                Use the slider to estimate the number of monitors.
              </p>

              <Slider
                min={1}
                max={4}
                step={1}
                value={[selectedRange]}
                onValueChange={value => setSelectedRange(value[0])}
              />
              <div className="mt-4 flex justify-between text-gray-300">
                <div className="pl-2">1k</div>
                <div className="pl-2">5k</div>
                <div className="pl-2">15k</div>
                <div>25k+</div>
              </div>
            </div>

            <div>
              <label
                className="mb-1 block font-bold text-gray-50"
                htmlFor={inputId}
              >
                Email
              </label>
              <input
                id={inputId}
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className="mt-2 h-[38px] w-full rounded-md border border-gray-700 bg-gray-900 px-3 text-sm text-gray-50 placeholder-gray-500 hover:border-gray-300 focus:border-gray-500 focus:outline-none"
              />
            </div>

            <hr className="mb-3 border-gray-700" />

            <Button
              type="submit"
              size="medium"
              className="text-md"
              style={{ fontSize: '1rem' }}
              disabled={email.trim().length === 0}
            >
              Book a demo
            </Button>
            <p className="text-center text-sm text-gray-500">
              You will be redirected to a calendar booking page
            </p>
          </form>
        </div>
      </div>
    </Modal>
  );
};
