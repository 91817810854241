import { Dialog } from '@headlessui/react';
import classNames from 'classnames';

import type { ExtractHeadlessUIProps } from '@/shared/utils/types.type';
import { cn } from '@/shared/utils/cn.utils';

type ModalSize = 'sm' | 'md' | 'lg';

export type ModalProps<TTag extends React.ElementType<any> = 'div'> =
  ExtractHeadlessUIProps<typeof Dialog<TTag>> & {
    size?: ModalSize;
    containerClassName?: string;
  };

export type MinimalModalProps = {
  onClose: ModalProps['onClose'];
};

const modalSizeClasses: Record<ModalSize, string> = {
  sm: 'max-w-sm',
  md: 'max-w-2xl',
  lg: 'max-w-5xl',
};

export const Modal = <TTag extends React.ElementType<any> = 'div'>({
  size = 'md',
  onClose,
  open = true,
  containerClassName,
  ...props
}: ModalProps<TTag>) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={cn('relative z-100', props.className)}
      {...props}
    >
      <div className="fixed inset-0 bg-blue-dark/80" aria-hidden="true" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-6 text-center">
          <Dialog.Panel
            className={cn(
              'w-full transform overflow-hidden bg-white p-12 align-middle',
              {
                [modalSizeClasses[size]]: size,
              },
              containerClassName,
            )}
          >
            {props.children}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export const ModalTitle = Dialog.Title;
