import {
  type ComponentPropsWithoutRef,
  type ElementType,
  useEffect,
} from 'react';
import { isTruthy } from 'remeda';
import { create } from 'zustand';

import { getStoreActions } from './utils';

import type {
  LegacyPlanErrorModal,
  PaymentCouponErrorModal,
  PaymentCouponSuccessModal,
  PaymentErrorModal,
  SubscriptionChangeNotPossibleModal,
  SuccessfulPurchaseModal,
  SuccessfulSubscriptionScheduleCancel,
} from '@/features/billing/components';
import { type BillingPeriodChangeSuccessModal } from '@/features/billing/components/billing-period-change-success-modal';
import { type SubscriptionChangeSuccessModal } from '@/features/billing/components/subscription-change-success-modal';
import type { CustomQuoteModal } from '@/pages/pricing/components/CustomQuoteModal/custom-quote-modal.component';
import type { ErrorModal, ModalProps } from '@/ui';

type ManagerModalFromComponent<T extends ElementType = 'div'> = Omit<
  ComponentPropsWithoutRef<T>,
  Exclude<keyof ModalProps, 'title'>
> &
  Partial<Pick<ModalProps, 'onClose'>>;

export const ModalType = {
  Error: 'Error',
  PaymentSuccess: 'PaymentSuccess',
  PaymentError: 'PaymentError',
  LegacyPlanError: 'LegacyPlanError',
  SubscriptionChangeNotPossible: 'SubscriptionChangeNotPossible',
  PaymentCouponSuccess: 'PaymentCouponSuccess',
  PaymentCouponError: 'PaymentCouponError',
  SuccessfulSubscriptionScheduleCancel: 'SuccessfulSubscriptionScheduleCancel',
  BillingPeriodChangeSuccess: 'BillingPeriodChangeSuccess',
  SubscriptionChangeSuccess: 'SubscriptionChangeSuccess',
  CustomQuote: 'CustomQuote',
} as const;

const NULL_MODAL = { type: null, props: null };

type ActiveModal =
  | typeof NULL_MODAL
  | {
      type: typeof ModalType.Error;
      props?: ManagerModalFromComponent<typeof ErrorModal>;
    }
  | {
      type: typeof ModalType.PaymentSuccess;
      props: ManagerModalFromComponent<typeof SuccessfulPurchaseModal>;
    }
  | {
      type: typeof ModalType.BillingPeriodChangeSuccess;
      props: ManagerModalFromComponent<typeof BillingPeriodChangeSuccessModal>;
    }
  | {
      type: typeof ModalType.PaymentError;
      props?: ManagerModalFromComponent<typeof PaymentErrorModal>;
    }
  | {
      type: typeof ModalType.SubscriptionChangeSuccess;
      props: ManagerModalFromComponent<typeof SubscriptionChangeSuccessModal>;
    }
  | {
      type: typeof ModalType.LegacyPlanError;
      props?: ManagerModalFromComponent<typeof LegacyPlanErrorModal>;
    }
  | {
      type: typeof ModalType.SubscriptionChangeNotPossible;
      props: ManagerModalFromComponent<
        typeof SubscriptionChangeNotPossibleModal
      >;
    }
  | {
      type: typeof ModalType.PaymentCouponSuccess;
      props: ManagerModalFromComponent<typeof PaymentCouponSuccessModal>;
    }
  | {
      type: typeof ModalType.PaymentCouponError;
      props?: ManagerModalFromComponent<typeof PaymentCouponErrorModal>;
    }
  | {
      type: typeof ModalType.SuccessfulSubscriptionScheduleCancel;
      props: ManagerModalFromComponent<
        typeof SuccessfulSubscriptionScheduleCancel
      >;
    }
  | {
      type: typeof ModalType.CustomQuote;
      props: ManagerModalFromComponent<typeof CustomQuoteModal>;
    };

export type ModalStore = {
  active: ActiveModal;
  actions: {
    open: (newActive: ActiveModal) => void;
    close: () => void;
  };
};

export const useModalStore = create<ModalStore>(set => ({
  active: NULL_MODAL,

  actions: {
    open: (newActive: ActiveModal) => {
      set({ active: newActive });
    },
    close: () => {
      set({ active: NULL_MODAL });
    },
  },
}));

export const useModalActions = () => useModalStore(getStoreActions);

// TODO: remove this effect, modals should be opened only programmatically
/**
 * Hook to open modal automatically when condition is met
 * @param modal to be opened
 * @param condition if true modal will be opened automatically
 */
export function useModalOpen(modal: ActiveModal, condition: boolean | unknown) {
  const modalActions = useModalActions();
  useEffect(() => {
    if (isTruthy(condition)) modalActions.open(modal);
  }, [condition, modal, modalActions]);
}
